import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react"; 
import axios from 'axios'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for styling
import { parse } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { child, get, ref } from 'firebase/database';
import { db } from '../../firebase';

const DailyTrends = () => {

  const uid = localStorage.getItem('uid')
  const [tracks, setTracks] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [uniqueDays, setUniqueDays] = useState([]);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [userTracks, setUserTracks] = useState([]);
  const [filterTracksObj, setFilterTracksObj] = useState({})
  const [filterPlatformsObj, setFilterPlatformsObj] = useState({})
  const [filterDatesObj, setFilterDatesObj] = useState({})
  const [isUserInteraction, setIsUserInteraction] = useState(false)
  const [sDate, setSDate] = useState(uniqueDays[startDate]?.day)
  const [eDate, setEDate] = useState(uniqueDays[startDate]?.day)
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()

  const fetchData = async (userID) => {
    try {
      const response = await axios.get(`https://bne-backend.vercel.app/stats/${userID}`);
      const user = response.data;
      const userTracks = user.tracks || [];
      setUserTracks(userTracks)
      orderData(userTracks, false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    get(child(ref(db, 'users'), uid))
    .then(snapshot => {
      const userID = snapshot.val().userID
      fetchData(userID);
    })
  }, []);


  const currentDate = () => {
    const day = String(new Date().getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
    const month = String(new Date().getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with leading zero
    const year = new Date().getFullYear(); // Get the full year
  
    return `${day}/${month}/${year}`; // Construct the "dd/mm/yyyy" format
  };


  const platformsNames = {
    "youtube": "YouTube",
    "tiktok": "TikTok",
    "instagram": "Instagram",
    "shazam": "Shazam",
    "spotify": "Spotify",
    "apple_music": "Apple Music",
    "amazon": "Amazon"
  }

  const orderData = (data, filter) => {

    ////// GETTING TRACKS, PLATFORMS, AND DATES
    let tracksObj = {}
    let datesObj = {}
    let platformsObj = {}
    for (let track of data) {
      if (!tracksObj[track.isrc]) tracksObj[track.isrc] = track.title
      const trackDates = track.dates
      for (let date of trackDates) {
        if (!datesObj[date.date]) datesObj[date.date] = [track.isrc]
        else datesObj[date.date].push(track.isrc)
        const datePlatforms = date.stats
        for (let platform of datePlatforms) {
          const platformName = platformsNames[platform.name]
          if (!platformsObj[platformName]) {
            if (platform.streams_total) platformsObj[platformName] = platform.streams_total
            if (platform.views_total) platformsObj[platformName] = platform.views_total
            if (platform.video_views_total) platformsObj[platformName] = platform.video_views_total
            if (platform.shazams_total) platformsObj[platformName] = platform.shazams_total
          }
        }
      }
    }


    ////// GETTING SPLATFORMS STREAMS IN A SPECIFIC DATE
    let chartData = {}
    if (filter === true) {

      for (let date in datesObj) {
        let datePlatforms = []
        for (let platform in platformsObj) {
          if (filterPlatformsObj[platform]) {
            let totalStreams = 0
            datesObj[date].forEach(trackIsrc => {
              if (filterTracksObj[trackIsrc]) {
                const track = data.find(item => item.isrc === trackIsrc)
                const trackDate = track.dates.find(item => item.date === date)
                const trackPlatform = trackDate.stats.find(item => platformsNames[item.name] === platform)
      
                if (trackPlatform && trackPlatform.streams_total) totalStreams += trackPlatform.streams_total
                if (trackPlatform && trackPlatform.views_total) totalStreams += trackPlatform.views_total
                if (trackPlatform && trackPlatform.video_views_total) totalStreams += trackPlatform.video_views_total
                if (trackPlatform && trackPlatform.shazams_total) totalStreams += trackPlatform.shazams_total
              }
            })
            datePlatforms.push({platform, streams: totalStreams})
          } else {
            datePlatforms.push({platform, streams: 0})
          }
        }
        chartData[date] = datePlatforms
      }
    } else {
      for (let date in datesObj) {
        let datePlatforms = []
        for (let platform in platformsObj) {
          let totalStreams = 0
          datesObj[date].forEach(trackIsrc => {
            const track = data.find(item => item.isrc === trackIsrc)
            const trackDate = track.dates.find(item => item.date === date)
            const trackPlatform = trackDate.stats.find(item => platformsNames[item.name] === platform)
  
            if (trackPlatform && trackPlatform.streams_total) totalStreams += trackPlatform.streams_total
            if (trackPlatform && trackPlatform.views_total) totalStreams += trackPlatform.views_total
            if (trackPlatform && trackPlatform.video_views_total) totalStreams += trackPlatform.video_views_total
            if (trackPlatform && trackPlatform.shazams_total) totalStreams += trackPlatform.shazams_total
          })
          datePlatforms.push({platform, streams: totalStreams})
        }
        chartData[date] = datePlatforms
      }
      
    }


    /////// MAKING THE CHART VARIABLES
    let platformsData = []
    const platformsNum = Object.keys(platformsObj).length
    for (let i = 0; i < platformsNum; i++) {
      let platData = []
      let platName = ''
      if (filter === true) {
        for (let date in chartData) {
          if (filterDatesObj[date]) {
            platData.push(chartData[date][i].streams)
            platName = chartData[date][i].platform
          }
        }
        platformsData.push({platform: platName, streams: platData})
      } else {
        for (let date in chartData) {
          platData.push(chartData[date][i].streams)
          platName = chartData[date][i].platform
        }
        platformsData.push({platform: platName, streams: platData})
      }
    }

    if (filter === true) {

      const mappedPlatforms = platformsData.map(item => ({...item, selected: filterPlatformsObj[item.platform] ? true : false}))
      setPlatforms(mappedPlatforms)
  
      const mappedTracks = data.map(item => ({...item, selected: filterTracksObj[item.isrc] ? true : false}))
      setTracks(mappedTracks)
  
      const days = []
      for (let day in chartData) {
        days.push({day, selected: filterDatesObj[day] ? true : false})
      }

      const dateRangeArray = Object.keys(filterDatesObj)

      const firstDay = days.findIndex(item => item.day === dateRangeArray[0])
      const lastDay = days.findIndex(item => item.day === dateRangeArray[dateRangeArray.length-1])
      setUniqueDays(days)
      setStartDate(firstDay)
      setEndDate(lastDay)

    } else {

      const mappedPlatforms = platformsData.map(item => ({...item, selected: true}))
      setPlatforms(mappedPlatforms)
  
      const mappedTracks = data.map(item => ({...item, selected: true}))
      setTracks(mappedTracks)
  
      const days = []
      for (let day in chartData) {
        days.push({day, selected: true})
      }
      setUniqueDays(days)
      setStartDate(0)
      setEndDate(days.length-1)

      setSDate(parseDate(days[0].day))
      setEDate(parseDate(days[days.length-1].day))

      setMinDate(parseDate(days[0].day))
      setMaxDate(parseDate(days[days.length-1].day))
    }

  }
  
  const handleFilters = () => {
    const selectedTracks = tracks.filter((track) => track.selected);
    const selectedPlatforms = platforms.filter(item => item.selected)

    let selectedPlatformsObj = {}
    for (let platform of selectedPlatforms) {
      selectedPlatformsObj[platform.platform] = true
    }
    setFilterPlatformsObj(selectedPlatformsObj)
    // localStorage.setItem('platformsFilter', JSON.stringify(selectedPlatformsObj))

    let selectedTracksObj = {}
    for (let track of selectedTracks) {
      selectedTracksObj[track.isrc] = true
    }
    setFilterTracksObj(selectedTracksObj)
    // localStorage.setItem('tracksFilter', JSON.stringify(selectedTracksObj))

    let datesObj = {}
    for (let i = startDate; i <= endDate; i++) {
      datesObj[uniqueDays[i].day] = true
    }
    setFilterDatesObj(datesObj)
    // localStorage.setItem('dateRange', JSON.stringify(datesObj))
    
  }


  useEffect(() => {
    orderData(userTracks, true)
  }, [filterDatesObj])


  const chartColors = [
    "#FF5733", // Bright Orange
    "#4285F4", // Vibrant Blue
    "#34A853", // Green
    "#9C27B0", // Purple
    "#E91E63", // Pink
    "#FFC107", // Amber
    "#795548", // Brown
    "#009688", // Teal
    "#607D8B"  // Blue Gray
  ];
  

  const series = platforms
  // .filter(item => item.selected)
    .map((platform, index) => ({
      name: platform.platform,
      type: 'line',
      smooth: true,
      symbol: 'circle',           
      symbolSize: 8,
      itemStyle: {
        color: chartColors[index] 
      },
      lineStyle: {
        color: chartColors[index] 
      },
      areaStyle: {
        opacity: 0.5,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: chartColors[index]
          },
          {
            offset: 1,
            color: '#fff'
          }
        ]),
      },
      data: platform.selected ? platform.streams : [],
    }));

  const chartOptions = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: platforms.filter(item => item.selected).map((item) => item.platform),
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: uniqueDays.filter(item => item.selected).map(item => item.day.split('/').splice(0, 2).join('/')), // X-axis labels
    },
    yAxis: {
      type: 'value', // Streams count on Y-axis
    },
    series: series,
  };

  const toggleTrackSelection = (index) => {
    const updatedTracks = [...tracks];
    updatedTracks[index].selected = !updatedTracks[index].selected;
    setTracks(updatedTracks);
    setIsUserInteraction(true)
  };

  const togglePlatformSelection = (index) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index].selected = !updatedPlatforms[index].selected;
    setPlatforms(updatedPlatforms);
    setIsUserInteraction(true)
  };

  useEffect(() => {
    if (isUserInteraction) {
      handleFilters()
      setIsUserInteraction(false)
    }
  }, [isUserInteraction])

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // Create a Date object
  };


  const convertToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, hence +1)
    const year = date.getFullYear(); // Get year
  
    return `${day}/${month}/${year}`; // Construct "dd/mm/yyyy" format
  };

  const handleStartDateChange = (date) => {
    const dateString = convertToDDMMYYYY(date)
    const dateIndex = uniqueDays.findIndex(item => item.day === dateString)
    if (dateIndex !== -1) {
      setStartDate(dateIndex)
      setIsUserInteraction(true)
      setSDate(date)
    }
  }

  const handleEndDateChange = (date) => {
    const dateString = convertToDDMMYYYY(date)
    const dateIndex = uniqueDays.findIndex(item => item.day === dateString)
    if (dateIndex !== -1) {
      setEndDate(dateIndex)
      setIsUserInteraction(true)
      setEDate(date)
    }
  }

  let [sDateChecker, setSDateChecker] = useState(true)
  useEffect(() => {
    if (eDate && sDateChecker) {
      let date = new Date(eDate);
      date.setTime(date.getTime() - 5 * 24 * 60 * 60 * 1000);
      handleStartDateChange(date);
      setSDateChecker(false)
    }
  }, [eDate])
  

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Daily Trends</h1>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                  <h2 className="dash-card-title">Streams</h2>
              </div>
              <div className="row px-1">
                <div className="col-lg-3 col-md-6 mb-3 px-1">
                    <div className="dash-form-group">
                        <label>Tracks</label>
                        <div className="dropdown dash-form-dropdown">
                          <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Tracks
                          </button>
                          <ul className='dropdown-menu'>
                            {tracks.map((track, index) => (
                              <li key={index}>
                                <div className='form-check'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    checked={track.selected}
                                    onChange={() => toggleTrackSelection(index)}
                                  />
                                  <label>{track.title}</label>
                                </div>
                              </li>
                            ))}
                          </ul>
                      </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-3 px-1">
                    <div className="dash-form-group">
                        <label>Platforms</label>
                        <div className="dropdown dash-form-dropdown">
                          <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Select Platforms
                          </button>
                          <ul className='dropdown-menu'>
                            {platforms.map((platform, index) => (
                              <li key={index}>
                                <div className='form-check'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    checked={platform.selected}
                                    onChange={() => togglePlatformSelection(index)}
                                  />
                                  <label>{platform.platform}</label>
                                </div>
                              </li>
                            ))}
                          </ul>
                      </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-3 px-1">
                    <div className="dash-form-group">
                        <label>Start Date</label>
                        <DatePicker
                            id="date-picker"
                            minDate={minDate}
                            maxDate={eDate}
                            selected={sDate}
                            onChange={handleStartDateChange}
                            dateFormat="dd/MM/yyyy"
                            locale={enGB} 
                            className='dash-form-input'
                          />
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-3 px-1">
                    <div className="dash-form-group">
                        <label>End Date</label>
                        <DatePicker
                            id="date-picker"
                            minDate={sDate}
                            maxDate={maxDate}
                            selected={eDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            locale={enGB} 
                            className='dash-form-input'
                          />
                    </div>
                </div>
            </div>
              {/* <Bar data={data} options={options} /> */}
              <ReactEcharts option={chartOptions} />
            </div>
          </div>
         
        </div>


    </div>
  )
}

export default DailyTrends