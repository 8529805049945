import { child, get, ref, set } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'

const MaintenanceToggle = () => {

    const [maintenanceOn, setMaintenanceOn] = useState(false)

    useEffect(() => {
        get(child(ref(db, 'admin'), 'maintenance_mode'))
        .then(snapshot => {
            if (snapshot.exists()) {
                setMaintenanceOn(snapshot.val().status)
            } else {
                setMaintenanceOn(false)
            }
        })
    }, [])

    const toggleMaintenance = () => {
        const newStatus = maintenanceOn === true ? false : true
        set(child(ref(db, 'admin'), 'maintenance_mode'), {
            status: newStatus
        })
        .then(() => setMaintenanceOn(newStatus))
    }

  return (
    <div className="d-flex justify-content-end">
        <div className='maintenance-toggle-container'>
            <h6>Maintenance Mode:</h6>
            <div 
                className={`maintenance-toggle ${maintenanceOn && 'active'}`}
                onClick={toggleMaintenance}
            ><div></div></div>
        </div>
    </div>
  )
}

export default MaintenanceToggle