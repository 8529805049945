import { BrowserRouter as Router, Route, useLocation, Routes } from "react-router-dom";
import RouteWithLayout from "./components/RouteWithLayout";
import { Layout } from "./layout";
import { AboutUsPage } from "./view/AboutUsPage";
import { ContactUsPage } from "./view/ContactUsPage";
import { DistributePage } from "./view/DistributePage";
import HomePage from "./view/HomePage";
import { CuratePage } from "./view/CuratePage";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from 'react'
import ScrollToTop from "./components/SrollToTop";
import Signup from "./view/Signup";
import Login from "./view/Login";
import PrivacyPolicyPage from "./view/PrivacyPolicyPage";
import Dashboard from "./view/Dashboard";
import Admin from "./view/Admin";
import UpdatePassword from "./view/UpdatePassword";
import ResetPassword from "./view/ReserPassword";
import CustomLogin from "./view/CustomLogin";
import UpdateEmail from "./view/UpdateEmail";
import mixpanel from 'mixpanel-browser';

const App = () => {

    // const location = useLocation();
    
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //   },
    // [location])

    mixpanel.init("4faf3049bdea5d1afb102070d4eb0d01", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

  return (
    <Router>
      <ScrollToTop />
        <Routes>
          {/* <Route
            path="/*"
            element={
              <Layout>
                <Routes>
                  <Route index element={<HomePage />} />
                  <Route path="home" element={<HomePage />} />
                  <Route path="distribute" element={<DistributePage />} />
                  <Route path="curate" element={<CuratePage />} />
                  <Route path="aboutus" element={<AboutUsPage />} />
                  <Route path="contactus" element={<ContactUsPage />} />
                  <Route path="privacypolicy" element={<PrivacyPolicyPage />} />
                </Routes>
              </Layout>
            }
          /> */}
          <Route index element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/:id" element={<CustomLogin />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/updatePassword" element={<UpdatePassword />} />
          <Route path="/updateEmail" element={<UpdateEmail />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Routes>
    </Router>
  );
};

export default App;
