import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase';
import { child, equalTo, get, orderByChild, query, ref, update } from 'firebase/database';
import emails from '../../arrays/emails.json'
import axios from 'axios';
import Pagination from '../Paginations';

const Splits = () => {

    const uid = localStorage.getItem('uid')
    const [users, setUsers] = useState([])
    const [tracks, setTracks] = useState([])
    const [filteredTracks, setFilteredTracks] = useState([])
    const [mySplits, setMySplits] = useState([])
    const [splits, setSplits] = useState([])
    const [inviteSplits, setInviteSplits] = useState([])
    const [tab, setTab] = useState(1)
    const [email, setEmail] = useState('')

    const closeRef = useRef()

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setEmail(snapshot.val().email)
            // console.log(uid)
            if (snapshot.val().releases !== undefined) {
                const liveReleases = snapshot.val().releases.filter(item => item.status === 'Distributed')
                const flatTracks = liveReleases.flatMap((release) => release.tracks)
                if (snapshot.val().splits !== undefined) {
                    setTracks(flatTracks.filter(track => snapshot.val().splits.find(split => split.track !== track.isrc) !== undefined))
                    setFilteredTracks(flatTracks.filter(track => snapshot.val().splits.find(split => split.track !== track.isrc) !== undefined))
                    
                    setSplits(snapshot.val().splits)
                    setMySplits(flatTracks.filter(track => snapshot.val().splits.find(split => split.track === track.isrc)))
                } else {
                    setTracks(flatTracks)
                    setFilteredTracks(flatTracks)
                }
            } else {
                //
            }
            if (snapshot.val().inviteSplits !== undefined) {
                setInviteSplits(snapshot.val().inviteSplits)
            }
        })

        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = Object.entries(snapshot.val()).map(([userId, user]) => ({
                id: userId,
                ...user
            }))
            setUsers(usersData)
        })
    }, [])

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tab === 2 ? filteredTracks.slice(indexOfFirstItem, indexOfLastItem) : mySplits.slice(indexOfFirstItem, indexOfLastItem)

    const totalPages = tab === 2 ? Math.ceil(filteredTracks.length / itemsPerPage) : Math.ceil(mySplits.length / itemsPerPage)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    

    //////// INVITE PAGINATION ////////
    const [inviteCurrentPage, setInviteCurrentPage] = useState(1);

    const inviteIndexOfLastItem = currentPage * itemsPerPage;
    const inviteIndexOfFirstItem = inviteIndexOfLastItem - itemsPerPage;
    const inviteCurrentItems = inviteSplits.slice(inviteIndexOfFirstItem, inviteIndexOfLastItem)

    const inviteTotalPages = Math.ceil(inviteSplits.length / itemsPerPage)

    const handleInvitePageChange = (pageNumber) => {
        setInviteCurrentPage(pageNumber);
    };


    ///////// SEARCH /////////
    const handleSearch = (event) => {
        const term = event.target.value;

        setCurrentPage(1)
    
        const filteredResults = tracks.filter((track) => {
          const { title, isrc } = track;
          const lowerCaseTerm = term.toLowerCase();
    
          // Check if the item's properties contain the search term
          return (
            title.toLowerCase().includes(lowerCaseTerm) ||
            isrc.toLowerCase().includes(lowerCaseTerm)
          );
        });
    
        setFilteredTracks(filteredResults);
    };

    const [isrc, setIsrc] = useState('')
    const [title, setTitle] = useState('')

    const filterByTrack = (trackId) => {
        const filteredSplits = mySplits.filter(split => split.track === trackId)
        return filteredSplits
    }

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }


    ///// EMAILS /////
    const sendEmails = async (email, percentage) => {
        const emailTemplate = emails[4]
        const subject = emailTemplate.subject
        const content = emailTemplate.content.replace(/\*\|track_title\|\*/g, title)
        try {

            const userExist = users.find(user => user.email === email)

            if (userExist) {
                const userInvites = userExist.inviteSplits || []
                userInvites.push({
                    user: email, 
                    revenueShare: 0, 
                    isrc: isrc,
                    isRecoupment: false,
                    percentage: percentage,
                    title: title
                })
                update(child(ref(db, 'users'), userExist.id), {
                    inviteSplits: userInvites
                })
            } else {
                const response = await axios.post('https://bne-backend.vercel.app/email', {
                    email: email,
                    subject,
                    content
                })
                return response.data;
            }

        } catch (error) {
          return null;
        }
    };


    const successBtn = useRef()
    const [saveText, setSaveText] = useState('Save')
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [newSplits, setNewSplits] = useState([])
    const [collaborator, setCollaborator] = useState('');
    const [recoup, setRecoup] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [recoupUser, setRecoupUser] = useState({
        email: 'Select User',
        index: 0
    })

    const addShare = () => {
        if (collaborator === '' || percentage * 1 === 0 || percentage === '') {
            handleError('Please enter valid values')
        } else if (percentage * 1 < 0) {
            handleError('Please enter valid values')
        } else {
            if (totalShares + percentage * 1 > 100) {
                handleError('Shares sum cannot exceed 100')
            } else {
                const userID = users.find(user => user.email.trim().toLowerCase() === collaborator.trim().toLowerCase())?.userID
                if (!userID) {
                    handleError('User not found')
                } else {
                    setSplits([...splits, { 
                        userID: userID,
                        user: collaborator, 
                        recoupment: 0,
                        share: percentage,
                        track: isrc,
                        title: title
                    }]);
                    setNewSplits([...newSplits, { 
                        userID: userID,
                        user: collaborator, 
                        recoupment: 0,
                        share: percentage,
                        track: isrc,
                        title: title
                    }]);
                    setCollaborator('');
                    setPercentage(0)
                }
            }
        }
    }

    const saveShares = async () => {
        setSaveText('Please Wait...')
        setBtnDisabled(true)
        try {
            // Use Promise.all to make parallel requests for each email
            const results = await Promise.all(newSplits.map(split => sendEmails(split.user, split.share)));
            update(child(ref(db, 'users'), uid), {
                splits: splits
            })
            .then(() => successBtn.current.click())
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
      };

    // const saveShares = () => {
    //     // update(child(ref(db, 'users'), uid), {
    //     //     splits: splits
    //     // })
    //     // .then(() => successBtn.current.click())
    //     console.log(splits, newSplits)
    // }


    const addRecoup = () => {
        const updatedSplits = [...splits];
        updatedSplits[recoupUser.index].recoupment = recoup;
        setSplits(updatedSplits);
        setNewSplits(prevSplits => prevSplits.map(item => item.user === recoupUser.email ? { ...item, recoupment: recoup } : item))
        setRecoup(0)
    }

    const trackSplits = splits.filter(item => item.track === isrc)
    const totalShares = trackSplits.reduce((acc, product) => acc + product.share * 1, 0);


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Splits</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-tabs">
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 1 && 'active'}`}
                            onClick={() => {setTab(1); setCurrentPage(1)}}
                        >My Splits</Link>
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 2 && 'active'}`}
                            onClick={() => {setTab(2); setCurrentPage(1)}}
                        >Add Splits</Link>
                        <Link 
                            to={'#'} 
                            className={`dash-tab ${tab === 3 && 'active'}`}
                            onClick={() => {setTab(3); setCurrentPage(1)}}
                        >Invited Splits</Link>
                    </div>
                    {
                        tab !== 3 && (
                            <>
                                {
                                    tab === 2 && (
                                        <input type="text" className='dash-input mb-0 mt-3' onChange={handleSearch} placeholder='Search by title/isrc...' />
                                    )
                                }
                                <div className="table-responsive mt-3">
                                    <table className="table dash-table">
                                        <thead>
                                            <tr>
                                                <td>Title</td>
                                                <td>ISRC</td>
                                                <td>Genre</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currentItems.map((track, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.title}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.isrc}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.genre}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                <Link to={'#'} onClick={() => {setIsrc(track.isrc); setTitle(track.title)}} data-bs-toggle="modal" data-bs-target="#addShareModal">
                                                                    <i 
                                                                        className="fi fi-rr-eye table-action-btn a-blue"
                                                                    ></i>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="table-pagination">
                                    {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                                    <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                        {pageNumber}
                                    </button>
                                    ))}
                                </div> */}
                                <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                            </>
                        )
                    }
                    {
                        tab === 3 && (
                            <>
                                <div className="table-responsive mt-3">
                                    <table className="table dash-table">
                                        <thead>
                                            <tr>
                                                <td>Title</td>
                                                <td>ISRC</td>
                                                <td>Percentage</td>
                                                <td>Revenue</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                inviteCurrentItems.map((track, i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.title}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.isrc}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                {track.percentage}%
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-item">
                                                                ${track.revenueShare.toFixed(2)}
                                                                {
                                                                    track.isRecoupment === true && <span className='ms-1'>(Recoupment)</span>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="table-pagination">
                                    {Array.from({ length: inviteTotalPages }, (_, index) => index + 1).map((pageNumber) => (
                                    <button key={pageNumber} className={inviteCurrentPage === pageNumber && 'active'} onClick={() => handleInvitePageChange(pageNumber)}>
                                        {pageNumber}
                                    </button>
                                    ))}
                                </div> */}
                                <Pagination currentPage={inviteCurrentPage} totalPages={inviteTotalPages} handlePageChange={handleInvitePageChange} />
                            </>
                        )
                    }
                </div>
            </div>
        </div>

        <div className="modal fade" id="addShareModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="addShareModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="shareModalLabel">Add Share</h1>
                        <a className='success-close-btn text-dark' href="/dashboard/splits">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Collaborator</td>
                                        <td className='text-center'>Percentage</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="table-item">{email}</div>
                                        </td>
                                        <td>
                                            <div className="table-item text-center">
                                                {100 - totalShares}%
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    {
                                        splits.map((share, i) => (
                                            share.track === isrc && (
                                                <tr key={i}>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            placeholder='Email' 
                                                            value={share.user} 
                                                            onChange={(e) => {
                                                                const updatedSplits = [...splits];
                                                                updatedSplits[i].user = e.target.value;
                                                                setSplits(updatedSplits);
                                                                setNewSplits(prevSplits => prevSplits.map(item => item.share === share.share ? { ...item, user: e.target.value } : item))
                                                            }}
                                                            className='dash-form-input' 
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="table-item text-center">
                                                            <input 
                                                                type="number" 
                                                                placeholder='0' 
                                                                value={share.share}
                                                                min={0}
                                                                onChange={(e) => {
                                                                    if (totalShares >= 100) {
                                                                        handleError('Shares sum cannot exceed 100')
                                                                        const updatedSplits = [...splits];
                                                                        updatedSplits[i].share = 0;
                                                                        setSplits(updatedSplits);
                                                                    } else if (e.target.value * 1 < 0) {
                                                                        handleError('Please enter a valid value')
                                                                    } else {
                                                                        const updatedSplits = [...splits];
                                                                        updatedSplits[i].share = e.target.value;
                                                                        setSplits(updatedSplits);
                                                                        setNewSplits(prevSplits => prevSplits.map(item => item.user === share.user ? { ...item, share: e.target.value } : item))
                                                                    }
                                                                }}
                                                                className='dash-form-input share-input me-1' 
                                                            />
                                                            %
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item d-flex justify-content-center mt-2">
                                                            <i 
                                                                className="fi fi-rr-trash table-action-btn a-red"
                                                                onClick={() => {
                                                                    const spiltUser = share.user
                                                                    const updatedSplits = [...splits]
                                                                    updatedSplits.splice(i, 1)
                                                                    setSplits(updatedSplits)
                                                                    setNewSplits(prevSplits => prevSplits.filter(item => item.user !== spiltUser))
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        ))
                                    }
                                    <tr>
                                        <td>
                                            <input type="text" placeholder='Email' value={collaborator} onChange={e => setCollaborator(e.target.value)} className='dash-form-input' />
                                        </td>
                                        <td>
                                            <div className="table-item text-center">
                                                <input type="number" min={0} placeholder='0' value={percentage} onChange={e => setPercentage(e.target.value)} className='dash-form-input share-input me-1' />
                                                %
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-item d-flex justify-content-center">
                                                <button className="dash-card-heading-btn share-btn" onClick={addShare}>Add</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {
                            error !== '' && <h6 className='text-danger text-center fw-bold mb-2'>{error}</h6>
                        }
                        <h6 className="mt-3 fw-bold">
                            Are there any collaborators that require recoupment before sharing royalies?
                            <br />
                            Add below
                        </h6>
                        <div className="table-responsive mt-3 pb-5">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Recoupment</td>
                                        <td className='text-center'>Amount</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        splits.map((share, i) => (
                                            share.track === isrc && share.recoupment * 1 > 0 && (
                                                <tr key={i}>
                                                    <td>
                                                        <div className="table-item">{share.user}</div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item text-center">{share.recoupment}</div>
                                                    </td>
                                                    <td>
                                                        <div className="table-item d-flex justify-content-center">
                                                            <i 
                                                                className="fi fi-rr-trash table-action-btn a-red"
                                                                onClick={() => {
                                                                    const updatedSplits = [...splits]
                                                                    updatedSplits[i].recoupment = 0
                                                                    setSplits(updatedSplits)
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        ))
                                    }
                                    {
                                        splits.find(item => item.track === isrc && item.recoupment * 1 > 0) === undefined && (
                                            <tr>
                                                <td>
                                                    <div className="dropdown dash-form-dropdown">
                                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            {recoupUser.email}
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li onClick={() => setRecoupUser({email: 'Select User', index: 0})}>Select User</li>
                                                            {
                                                                splits.map((split, i) => (
                                                                    split.track === isrc && (
                                                                        <li key={i} onClick={() => setRecoupUser({email: split.user, index: i})}>
                                                                            {split.user}
                                                                        </li>
                                                                    )
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item text-center">
                                                        $
                                                        <input type="number" min={0} placeholder='0' value={recoup} onChange={e => setRecoup(e.target.value)} className='dash-form-input share-input ms-1' />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex justify-content-center">
                                                        <button className={`dash-card-heading-btn share-btn ${recoupUser.email === 'Select User' || recoup * 1 <= 0 ? 'disabled' : ''}`} disabled={recoupUser.email === 'Select User' || recoup * 1 <= 0 ? true : false} onClick={addRecoup}>Add</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-center">
                            <a href='/dashboard/splits' className="dash-card-heading-btn cancel-btn share-btn me-2">Cancel</a>
                            <button className="dash-card-heading-btn share-btn" data-bs-toggle="modal" data-bs-target="#confirmModal">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="modal fade" id="confirmModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/splits">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <h5>Are you sure you want to make these changes?</h5>
                        <div className="d-flex justify-content-center mt-3">
                            <button className="dash-card-heading-btn cancel-btn share-btn me-2" data-bs-toggle="modal" data-bs-target="#addShareModal">Cancel</button>
                            <button className={`dash-card-heading-btn share-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={saveShares}>{saveText}</button>
                            <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/dashboard/splits">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Splits Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Splits